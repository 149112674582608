import React, { useEffect } from "react";
import { useAppState } from "@state/state";
import { IndexHero, IndexFilter, IndexList } from "@modules/indexes/index";
import { useBlogIndexData, useAllBlogPostsData } from "@staticQueries";
import { PreFooter, SEO } from "@common";
import { Section, Container } from "@ui";

import { t } from "@translations";

/* eslint react/prop-types: 0 */

const BlogIndex = ({ pageContext }) => {
  const { meta, hero, prefooter } = useBlogIndexData();
  const { categories } = pageContext;
  const [{ indexPages }, dispatch] = useAppState();
  const { blog } = indexPages;

  useEffect(() => {
    dispatch({
      type: "setHeaderTheme",
      headerTheme: {
        bgColor: "blue",
        showSearch: true,
        lockup: {
          size: "small",
          color: "blue",
        },
      },
    });
  }, []);

  const { posts } = useAllBlogPostsData();

  const filters = [
    {
      title: t("all"),
      id: "",
    },
    ...categories,
  ];

  return (
    <>
      <SEO {...meta} />
      <IndexHero heading={hero.heading} description={hero.description} />

      <Section hasPaddingTop={false} hasPaddingBottom={false}>
        <Container>
          <IndexFilter
            categories={filters}
            categoryValue={blog.category}
            indexName="blog"
          />
        </Container>
      </Section>

      <Section size="sm">
        <Container>
          <IndexList items={posts} state={blog} type="blog" />
        </Container>
      </Section>

      <PreFooter {...prefooter} />
    </>
  );
};

export default BlogIndex;
